import React, { useState, useEffect } from 'react';
import './FormInput.css';

interface InputProps {
  inputLabel?: string;
  inputType?: string;
  inputName?: string;
  inputPlaceholder?: string;
  inputValue?: string;
  regexType?: string;
  submitButtonClicked?: boolean;
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
}

function FormInput(props: InputProps) {
  const { inputLabel, inputType, inputName, inputPlaceholder, regexType, inputValue, submitButtonClicked, onInputChange, onClick } = props;

  const [isValid, setIsValid] = useState(true);
  const [errorType, setErrorType] = useState<string>('');

  const textRegex = /^[a-zA-ZäöüßÄÖÜ\s]{4,}$/;
  const usernameRegex = /^[a-zA-Z0-9_äöüßÄÖÜ]{7,}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@äöüßÄÖÜ]+$/;

  const handleInputValidation = (value: string, regexType?: string) => {
    if (submitButtonClicked) {
      if (value.trim() === '') {
        setErrorType('empty');
        return false;
      }else{
        setErrorType('');
      }
    }

    switch (regexType) {
      case 'email':
        return emailRegex.test(value);
      case 'text':
        return textRegex.test(value);
      case 'username':
        return usernameRegex.test(value);
      default:
        return true;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setIsValid(handleInputValidation(value, props.regexType));
    onInputChange && onInputChange(event);
  };

  useEffect(() => {
    setIsValid(handleInputValidation(inputValue || '', regexType));
  }, [submitButtonClicked]);
  
  return (
    <div className={isValid ? "form-input-container" : "form-input-container invalidForm"}>
      <label>{inputLabel}</label>
      <input onClick={onClick} type={inputType} placeholder={inputPlaceholder} name={inputName} onChange={handleChange} value={inputValue}></input>
      {!isValid && submitButtonClicked && errorType === '' &&(
        <ErrorMessage errorType={props.regexType} />
      )}
      {!isValid && submitButtonClicked && errorType === 'empty'&& (
        <ErrorMessage errorType={'empty'} />
      )}
    </div>
  );
}

interface ErrorMessage {
  errorType?: string;
}

export function ErrorMessage(props: ErrorMessage) {
  const { errorType } = props;

  if (errorType === 'text') {
    return (
      <span className='formInputErrorMessage'>Der Wert muss mindestens 4 Zeichen lang sein</span>
    );
  } else if (errorType === 'email') {
    return (
      <span className='formInputErrorMessage'>Die eingegebene E-Mail muss gültig sein</span>
    );
  } else if (errorType === 'username') {
    return (
      <span className='formInputErrorMessage'>Der Benutzername muss mindestens 6 Zeichen lang sein</span>
    );
  } else if (errorType === 'empty') {
    return (
      <span className='formInputErrorMessage'>Dieses Feld ist erforderlich</span>
    );
  } else {
    return null;
  }
}

export default FormInput;
