import './LoadingScreen.css';

const LoadingComponent = () => {
  return (
    <div className="loading-container">
      <div className="loading-overlay"></div>
      <div className="loading-content">
        <img src="/uploads/franchise/BrandLogo.png" alt="Loading" />
      </div>
    </div>
  );
};

export default LoadingComponent;
