import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './Navbar.css';
import {AiOutlineMenu, AiOutlineClose} from 'react-icons/ai';

interface Props {}

function Navbar(props: Props) {
    
    const[isActiveMobileMenu, setIsActiveMobileMenu] = useState<boolean>(false);
    const [isNavbarFixed, setIsNavbarFixed] = useState(false);

    const handleOnClick = () => {
        setIsActiveMobileMenu(!isActiveMobileMenu);
    }

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.pageYOffset;
            setIsNavbarFixed(scrollPosition > 250);
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return (
        <nav>
            <div className={isNavbarFixed ? "navbarContainer navbarContainerFixed" : "navbarContainer"}>
                <a href='/'><img src="/uploads/franchise/BrandLogo.png" className='navbarBrandLogo'></img></a>
                <div className="navbarRightLinks">
                    <ul className={`navbarLinksList ${isActiveMobileMenu ? 'active' : ''}`}>
                        <li className="navbarLink"><Link to={'/'}>STARTSEITE</Link></li>
                        <li className="navbarLink"><Link to={'/menu'}>SPEISEKARTE</Link></li>
                        <li className="navbarLink"><Link to={'/about'}>ÜBER UNS</Link></li>
                        <li className="navbarLink"><Link to={'/franchise'}>FRANCHISE</Link></li>
                        <li className="navbarLink"><Link to={'/contact'}>KONTAKT</Link></li>
                    </ul>
                    {!isActiveMobileMenu ? (
                        <AiOutlineMenu className='hamburgerMenu open' onClick={handleOnClick} />
                    ) : (
                        <AiOutlineClose className='hamburgerMenu close' onClick={handleOnClick} />
                    )}
                </div>
            <img src="/uploads/franchise/Chocolate.png" className='navbarChocolate'></img>
            </div>
        </nav>
    )
}

export default Navbar
