import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import Home from './pages/Home/Home';
import Menu from './pages/Menu/Menu';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Franchise from './pages/Franchise/Franchise';
import Dashboard from './admin/pages/Dashboard/Dashboard';
import Categories from './admin/pages/Categories/Categories';
import Products from './admin/pages/Products/Products';
import Login from './admin/pages/Login/Login';  
import Protected from './components/Protected/Protected';
import InvalidRoute from './pages/InvalidRoute/InvalidRoute';
import Impressum from './pages/Impressum/Impressum';
import AGB from './pages/AGB/AGB';
import ProductInformation from './pages/ProductInformation/ProductInformation';
import CookieConsent from './components/CookieConsent/CookieConsent';
const userService = require('./api/userService');

function App() {

  useEffect(() => {
    const consentGiven = localStorage.getItem('cookieConsent');
    if (consentGiven) {
      loadGoogleAnalytics(); // Load GA if consent is already given
    }
  }, []);

  const loadGoogleAnalytics = () => {
    const script = document.createElement('script');
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-DE43JFSCFM";
    document.head.appendChild(script);
  
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function() {
        window.dataLayer.push(arguments);
      }
      window.gtag('js', new Date());
      window.gtag('config', 'G-DE43JFSCFM');
    };
  
    script.onerror = () => {
      console.error('Google Analytics script failed to load');
    };
  };

  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/menu' element={<Menu/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/franchise' element={<Franchise/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/impressum' element={<Impressum/>}/>
          <Route path='/agb' element={<AGB/>}/>
          <Route path='/product-information/:productName' element={<ProductInformation/>}></Route>
          
          <Route path='/dashboard' element={<Protected><Dashboard/></Protected>}>
            <Route path='/dashboard/categories' element={<Categories/>}/>
            <Route path='/dashboard/products' element={<Products/>}/>
          </Route>

          <Route path='/admin-dashboard-login' element={<Login/>}/>
          <Route path="*" element={<InvalidRoute/>} />
        </Routes>
      </Router>
      <CookieConsent/>
    </>
  );
}

export default App;
