import {useState, useEffect} from 'react';
import "./AddProduct.css";
import FormInput from '../../../../../components/FormInput/FormInput';
import {AiOutlineCheck} from 'react-icons/ai';
import {ImCancelCircle} from 'react-icons/im';
import { toast} from 'react-toastify';
import LoadingComponent from '../../../../../components/LoadingScreen/LoadingScreen';
const categoryService = require ('../../../../../api/categoryService');
const productService = require ('../../../../../api/productService');


interface CategoryItem{
    categoryID: number,
    categoryName: string
}

interface ProductItem{
    productID: number,
    categoryID: number,
    productName: string,
    productImage: string,
    productPrice: number,
    productDescription: string
}

function AddProduct() {

    useEffect(() => {
        
        const getSimpleCategories = async () => {
            const response = await categoryService.getSimpleCategories();

            if(response){
                setCategories(response)
            }
        }
        
        getSimpleCategories();
    }, []);

    const [categories, setCategories] = useState<CategoryItem[] | null>();

    const [productName, setProductName] = useState('');
    const [productPrice, setProductPrice] = useState<number>();
    const [productDescription, setProductDescription] = useState(" ");
    const [productIngridients, setProductIngridients] = useState(" ");
    const [productCategory, setProductCategory] = useState<number | undefined>(undefined);
    const [productImage, setProductImage] = useState<File | null>(null);

    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const validateInputNotNull = () => {

        if(!productName || !productImage || !productPrice || !productCategory){
            return false;
        }

        const hasInvalidFormClass = document.querySelectorAll('.invalidForm').length > 0;
        return !hasInvalidFormClass;
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setSubmitButtonClicked(true);

        if (validateInputNotNull() && productPrice !== undefined && productCategory !== undefined) {
            setIsLoading(true);

            const formData = new FormData();
            formData.append('productName', productName);
            formData.append('productImage', productImage as File);
            formData.append('productPrice', productPrice.toString());
            formData.append('productDescription', productDescription);
            formData.append('productIngridients', productIngridients);
            formData.append('categoryID', productCategory.toString());

            try{
                const success = await productService.addProduct(formData);

                if (success) {
                    toast.success('Produkt hinzugefügt!', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => {
                        window.location.href = ('/dashboard/products');
                    }, 1500);
                } else {
                    toast.error('Produkt konnte nicht hinzugefügt werden!', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }catch(error) {
                toast.error('Fehler beim Hinzufügen des Produkts!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }else{
            toast.error('Stellen Sie sicher, dass Sie alle Produktinformationen korrekt ausfüllen!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        setIsLoading(false);
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setProductImage(event.target.files[0]);
        }
    };

    return (
        <div className='add-product-container'>
            {isLoading && <LoadingComponent/>}
            <div className="add-product-content">
                <h4 className="add-product-header">Fügen Sie ein neues Produkt hinzu. Füllen Sie unbedingt alle Felder aus</h4>
                <form className="add-product-form">
                    <FormInput submitButtonClicked={submitButtonClicked} inputLabel='Produktname' inputType='text' regexType='text' onInputChange={(e) => setProductName(e.target.value)} inputValue={productName}/>
                    <div className="form-input-container">
                        <label>Produktpreis</label>
                        <input type='number' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProductPrice(parseFloat(e.target.value) || 0)}/>
                    </div>
                    <div className="form-textarea-container">
                        <label>Beschreibung</label>
                        <textarea onChange={(e) => setProductDescription(e.target.value)}></textarea>
                    </div>
                    <div className="form-textarea-container">
                        <label>Zutaten</label>
                        <textarea onChange={(e) => setProductIngridients(e.target.value)}></textarea>
                    </div>
                    <div className='form-dropdown-container'>
                        <label>Kategorie</label>
                        <select className='form-dropdown' onChange={(e) => setProductCategory(Number(e.target.value))}>
                            <option value="">Wählen Sie eine Kategorie</option>
                            {categories && categories.map((category) => (
                                <option key={category.categoryID} value={category.categoryID}>{category.categoryName}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="add-product-file-input" className="add-product-file-label">Choose a File</label>
                        <input id="add-product-file-input" type="file" accept=".png, .jpg, .jpeg" onChange={handleImageChange}/>
                    </div>
                    <div className="form-actions">
                        <button className="approve-action-btn" onClick={handleSubmit}><AiOutlineCheck/> Hinzufügen</button>
                        <button className="cancel-action-btn" onClick={() => window.location.href = ('/dashboard/products')}><ImCancelCircle/> Stornieren</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddProduct
