import {useState, useEffect} from 'react';
import './Hero.css';
import './HeroItem/HeroItem.css';
import HeroItem from './HeroItem/HeroItem';

const heroItems = [
    {
        heroItemTitle: "Dieses Croissant hat ein' an der Waffel!",
        heroButtonText: 'Entdecken Sie das Menü',
        heroImage: `/uploads/franchise/MandelChoco.webp`
    },{
        heroItemTitle: "Gönn' dir einen Eiskaffee!",
        heroButtonText: 'Entdecken Sie das Menü',
        heroImage: `/uploads/franchise/IceCoffee.webp`
    },{
        heroItemTitle: "Versüß' dir dein Leben mit einem Milkshake!",
        heroButtonText: 'Entdecken Sie das Menü',
        heroImage: `/uploads/franchise/Milkshake.webp`
    }
];

function Hero() {
    const [currentItemIndex, setCurrentItemIndex] = useState(0);

    const handleNextButtonClick = () => {
        setCurrentItemIndex((prevIndex) => (prevIndex + 1) % heroItems.length);
    };

    const handlePrevButtonClick = () => {
        setCurrentItemIndex((prevIndex) => (prevIndex - 1 + heroItems.length) % heroItems.length);
        
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentItemIndex((prevIndex) => (prevIndex + 1) % heroItems.length);
        }, 3500);
    
        return () => {
          clearInterval(timer);
        };
      }, [heroItems.length]);

    return (
        <>
            <section id='heroSection'>
                <HeroItem
                    heroItemTitle={heroItems[currentItemIndex].heroItemTitle}
                    heroButtonText={heroItems[currentItemIndex].heroButtonText}
                    heroImage={heroItems[currentItemIndex].heroImage}
                    handleNextButtonClick={handleNextButtonClick}
                    handlePrevButtonClick={handlePrevButtonClick}
                />
            </section>
            <img src="/uploads/franchise/HeroWave.png" className='waveImage'></img>
        </>
    );
}

export default Hero
