import { useState, useEffect } from 'react';
import "./Products.css";
import { toast} from 'react-toastify';
import LoadingComponent from '../../../components/LoadingScreen/LoadingScreen';
import {MdOutlineAdd} from 'react-icons/md'
import {AiOutlineStar, AiFillStar} from 'react-icons/ai'
import AddProduct from './Actions/Add/AddProduct';
import EditProduct from './Actions/Edit/EditProduct';
const productService = require('../../../api/productService');

interface Props { }

interface ProductItem {
  categoryID: number;
  productID: number;
  productName: string;
  productImage: string;
  productPrice: number;
  productDescription: string;
  productIngridients: string;
  featuredProduct: boolean;
}

function Products(props: Props) {

  useEffect(() => {
    const getAllProducts = async () => {
      const response = await productService.getAllProducts();

      if (response) {
        setProducts(response);
      }
    };

    getAllProducts();
  }, []);

  const [products, setProducts] = useState<ProductItem[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [addProductStatus, setAddProductStatus] = useState(false);

  const [editProduct, setEditProduct] = useState<ProductItem | null>(null);
  const [editProductStatus, setEditProductStatus] = useState(false);


  const handleAddProduct = async () => {
    setAddProductStatus(!addProductStatus);
  }

  const handleEditProduct = (product: ProductItem) => {
    setEditProduct(product);
    setEditProductStatus(true);
  }

  const handleDelete = async (productID: number) => {

    setIsLoading(true);
    const response = await productService.deleteProduct(productID);

    if(response){
        toast.success('Produkt gelöscht!', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        setTimeout(() => {
          window.location.href = ('/dashboard/products');
        }, 3000);
    }else{
        toast.error('Produkt konnte nicht gelöscht werden!', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        setTimeout(() => {
          window.location.href = ('/dashboard/products');
        }, 3000);
    }
    setIsLoading(false);
  }

  const handleAddFeature = async (productID: number) => {

    const response = await productService.addFeaturedProduct(productID);

    if(response){
      toast.success('Produkt zur Featured List hinzugefügt!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    setTimeout(() => {
      window.location.href = ('/dashboard/products');
    }, 2000);
    }else{
      toast.error('Das Produkt konnte nicht zur Liste der vorgestellten Artikel hinzugefügt werden!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        window.location.href = ('/dashboard/products');
      }, 2000);
    }
  }

  const handleRemoveFeature = async (productID: number) => {
    const response = await productService.removeFeaturedProduct(productID);

    if(response){
      toast.success('Produkt aus der Featured-Liste entfernt!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    }else{
      toast.error('Das Produkt konnte nicht aus der Liste der vorgestellten Produkte entfernt werden!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    
    setTimeout(() => {
      window.location.href = ('/dashboard/products');
    }, 1500);
  }

  return (
    <div className="dashboard-table-container">
        <button className='add-product-btn' onClick={() => handleAddProduct()}><MdOutlineAdd/> Produkt hinzufügen</button>
        {isLoading && <LoadingComponent/>}
        {addProductStatus && <AddProduct/>}
        {editProductStatus && editProduct && (
          <EditProduct 
            productID={editProduct.productID} 
            categoryID={editProduct.categoryID} 
            productName={editProduct.productName} 
            productImage={editProduct.productImage}
            productDescription={editProduct.productDescription}
            productIngridients={editProduct.productIngridients}
            productPrice={editProduct.productPrice}
          />
        )}
        <table className='dashboard-table'>
            <thead>
            <tr className='table-header-row'>
                <th>Produktname</th>
                <th>Produktbild</th>
                <th>Produktpreis</th>
                <th>Produktbeschreibung</th>
                <th>Featured</th>
            </tr>
            </thead>
            <tbody>
              {products && products.length > 0 ? (
                products.map((product) => (
                  <tr key={product.productID} className='table-row'>
                    <td>{product.productName}</td>
                    <td><img src={product.productImage} className='product-image' alt={product.productName} /></td>
                    <td>{product.productPrice}€</td>
                    <td><p className='dashboard-product-description'>{product.productDescription}</p></td>
                    {product.featuredProduct ? (
                      <td className='dashboard-product-featured-icon featured'><AiFillStar onClick={() => handleRemoveFeature(product.productID)}/></td>
                    ): (
                      <td className='dashboard-product-featured-icon not-featured'><AiOutlineStar onClick={() => handleAddFeature(product.productID)}/></td>
                    )}
                    <td><button className='edit-product-btn' onClick={() => handleEditProduct(product)}>Bearbeiten</button></td>
                    <td><button className='delete-product-btn' onClick={() => handleDelete(product.productID)}>Löschen</button></td>
                  </tr>
                ))
              ) : (
                <tr className='table-row'>
                  <td colSpan={6}>
                    <div className='no-products-available'>
                      <p>Keine Produkte verfügbar</p>
                    </div>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
    </div>
  );
}

export default Products;
