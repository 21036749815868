import {useState, useEffect} from 'react'
import './Login.css';
import FormInput from "../../../components/FormInput/FormInput"
import { toast} from 'react-toastify';
const userService = require('../../../api/userService');

function Login() {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const validateInputNotNull = () => {

        if(!username || !password){
            return false;
        }

        const hasInvalidFormClass = document.querySelectorAll('.invalidForm').length > 0;
        return !hasInvalidFormClass;
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setSubmitButtonClicked(true);

        if (validateInputNotNull()) {
            setIsLoading(true);

            try{
                const response = await userService.login(username, password);

                if(response){
                    toast.success('Erfolgreich eingeloggt!', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => {
                        window.location.href = ('/dashboard');
                    }, 1500);
                }else{
                    toast.error('Ungültige Zugangsdaten!', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }catch(error){
                toast.error('Fehler beim Versuch, sich anzumelden!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setTimeout(() => {
                    window.location.href = ('/admin-dashboard-login');
                }, 2000);
            }
        }else{
            toast.error('Stellen Sie sicher, dass Sie alle Anmeldeinformationen korrekt ausfüllen!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        setIsLoading(false);
    };

    return (
        <section className="dashboard-login-section">
            <div className="dashboard-login-overlay"></div>
            <div className="dashboard-login-content">
                <h4 className='dashboard-login-content-header'>Dashboard Login</h4>
                <hr></hr>
                <form className="dashboard-login-form">
                    <FormInput inputLabel="Nutzername" inputType='text' regexType='username' onInputChange={(e) => setUsername(e.target.value)} inputValue={username} submitButtonClicked={submitButtonClicked}/>
                    <FormInput inputLabel="Passwort" inputType='password' onInputChange={(e) => setPassword(e.target.value)} inputValue={password} submitButtonClicked={submitButtonClicked}/>
                    <div className="form-actions">
                        <button className="login-action-btn" onClick={handleSubmit}>Einloggen</button>
                    </div>
                </form>
                
            </div>
        </section>
    )
}

export default Login
