import axios from 'axios';

export const getAllProducts = async () => {
    try{
        
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/product/get-products`);

        if(response && response.data){
            return response.data.map((item) => ({
                productID: item.productID,
                categoryID: item.categoryID,
                productName: item.productName,
                productImage: item.productImage,
                productPrice: item.productPrice,
                productDescription: item.productDescription,
                productIngridients: item.productIngridients,
                featuredProduct: item.featuredProduct
            }));
        }

    }catch(error){
        console.error('Error getting products');
        throw error;
    }
}

export const getProductsByCategory = async (categoryID) => {
    try{
        if (!categoryID) {
            throw new Error('Category name is required');
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/product/get-products-by-category/${categoryID}`);

        if(response && response.status === 200){
            return response.data.map((item) => ({
                productID: item.productID,
                categoryID: item.categoryID,
                productName: item.productName,
                productImage: item.productImage,
                productPrice: item.productPrice
            }));
        }else{
            return [];
        }

    }catch(error){
        if (error.response && error.response.status === 404) {
            return [];
        }
        
        console.error('Error getting products');
        throw error;
    }
};

export const getProductInfo = async (productName) => {
    try{

        if(!productName){
            window.location.href = '/';
            return null;
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/product/get-product-information/${productName}`)

        if(response && response.status === 200){
            return response.data.map((item) => ({
                productImage: item.productImage,
                productIngridients: item.productIngridients,
                productPrice: item.productPrice
            }));
        }else{
            return [];
        }
    }catch(error){
        console.error('Error getting product information');
        throw error;
    }
}

export const addProduct = async (formData) => {
    try {

        const tokenCookie = document.cookie
        .split(';')
        .find((cookie) => cookie.trim().startsWith('token='));

        if(!tokenCookie){
            window.location.href ='/';
            return null;
        }

        const token = tokenCookie.split('=')[1];

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/add`,
            formData,{
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }
        );

        if (response.status === 200) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            window.location.href = ('/');
        }
        console.error('Error adding product');
        throw error;
    }
};

export const deleteProduct = async (productID) => {
    try{

        if(!productID){
            return false;
        }

        const tokenCookie = document.cookie
        .split(';')
        .find((cookie) => cookie.trim().startsWith('token='));

        if(!tokenCookie){
            window.location.href ='/';
            return null;
        }

        const token = tokenCookie.split('=')[1];

        const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/product/delete/${productID}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        });

        if(response.status === 200){
            return true;
        }else{
            return false;
        }

    }catch(error){
        if (error.response && error.response.status === 401) {
            window.location.href = ('/');
        }
        console.error(error);
        return false;
    }
}

export const deleteProductImage = async (productID) => {
    try {
        if (!productID) {
            throw new Error('Product id is required');
        }

        const tokenCookie = document.cookie
        .split(';')
        .find((cookie) => cookie.trim().startsWith('token='));

        if(!tokenCookie){
            window.location.href ='/';
            return null;
        }

        const token = tokenCookie.split('=')[1];

        const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/product/delete-image/${productID}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        });

        if(response){
            return true;
        }else{
            return false;
        }
        
    } catch (error) {
        if (error.response && error.response.status === 401) {
            window.location.href = ('/');
        }
        console.error('Error deleting product image');
        throw error;
    }
};


export const updateProductInformation = async (productID, productName, categoryID, productDescription, productIngridients,productPrice) => {
    try {

        if (!productID || !productName || !categoryID || productDescription === undefined || productDescription === null || !productPrice || productIngridients === undefined || productIngridients === null){
            throw new Error('Certain values are missing');
        }

        const tokenCookie = document.cookie
        .split(';')
        .find((cookie) => cookie.trim().startsWith('token='));

        if(!tokenCookie){
            window.location.href ='/';
            return null;
        }

        const token = tokenCookie.split('=')[1];

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/update-information/${productID}`,
            { productID, productName, categoryID, productDescription, productPrice, productIngridients}, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }
        );

        return response.data;
        
    } catch (error) {
        if (error.response && error.response.status === 401) {
            window.location.href = ('/');
        }
        console.error('Error updating product information:', error);
        throw error;
    }
};

export const uploadProductImage = async (productName, productImage) => {
    try {
        if (!productName || !productImage) {
            throw new Error('Product name and image are required.');
        }

        const tokenCookie = document.cookie
        .split(';')
        .find((cookie) => cookie.trim().startsWith('token='));

        if(!tokenCookie){
            window.location.href ='/';
            return null;
        }

        const token = tokenCookie.split('=')[1];

        const formData = new FormData();
        formData.append('productName', productName);
        formData.append('productImage', productImage);

        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/product/upload-image`,
            formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        );

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Error uploading product image');
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            window.location.href = ('/');
        }
        console.error('Error uploading product image:', error);
        throw error;
    }
};

export const getFeaturedProducts = async () => {
    try{  

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/product/get-featured-products`);

        if(response && response.status === 200){
            return response.data.map((item) => ({
                productID: item.productID,
                productName: item.productName,
                productImage: item.productImage,
                productDescription: item.productDescription
            }));
        }else{
            return [];
        }
        
    }catch(error){
        console.error('Error getting featured products');
        throw error;
    }
}

export const addFeaturedProduct = async (productID) => {
    try{
        if(!productID){
            throw new Error('Invalid function parameters for API request');
        }
    
        const tokenCookie = document.cookie
            .split(';')
            .find((cookie) => cookie.trim().startsWith('token='));
    
        if(!tokenCookie){
            window.location.href ='/';
            return null;
        }
    
        const token = tokenCookie.split('=')[1];

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/add-featured`, 
            {productID},
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );

        if(response.status === 200){
            return true;
        }else{
            return false;
        }

    }catch(error){
        if (error.response && error.response.status === 401) {
            window.location.href = ('/');
        }
        console.error("Error adding product to featured list.");
        throw error;
    }
}

export const removeFeaturedProduct = async (productID) => {
    try{
        if(!productID){
            throw new Error('Invalid function parameters for API request');
        }
    
        const tokenCookie = document.cookie
            .split(';')
            .find((cookie) => cookie.trim().startsWith('token='));
    
        if(!tokenCookie){
            window.location.href ='/';
            return null;
        }
    
        const token = tokenCookie.split('=')[1];

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/remove-featured`, 
            {productID},
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );

        if(response.status === 200){
            return true;
        }else{
            return false;
        }

    }catch(error){
        if (error.response && error.response.status === 401) {
            window.location.href = ('/');
        }
        console.error("Error removing product from featured list.");
        throw error;
    }
}