import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import './HeroItem.css';
import {BsArrowLeftCircle, BsArrowRightCircle} from 'react-icons/bs';

interface HeroItemProps {
    heroItemTitle: string;
    heroButtonText: string;
    heroImage: string;
    heroButtonLink?: string;
    handleNextButtonClick: () => void;
    handlePrevButtonClick: () => void;
}

function HeroItem(props: HeroItemProps) {
    const {heroItemTitle, heroButtonText, heroImage, heroButtonLink, handleNextButtonClick, handlePrevButtonClick} = props

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      setLoaded(true);
  
      const timeout = setTimeout(() => {
        setLoaded(false);
      }, 1500);
  
      return () => {
        clearTimeout(timeout);
      };
    }, [heroItemTitle]);

    return (
        <div className='heroItemContainer'>
            <div className="heroItemContainerLeft">
              <div className="heroText">
                <h4 className={`slide-left ${loaded ? 'slideInLeft' : ''}`}>{heroItemTitle}</h4>
              </div>
              <div className='heroActions'>
                <Link to={'/menu'}><button className={`primaryButton ${loaded ? 'slideInBottom' : ''}`}>{heroButtonText}</button></Link>
                <div className="heroSliderActions">
                  <BsArrowLeftCircle onClick={handlePrevButtonClick} className={`slide-left ${loaded ? 'slideInLeft' : ''}`}/>
                  <BsArrowRightCircle onClick={handleNextButtonClick} className={`${loaded ? 'slideInBottom' : ''}`}/>
                </div>
              </div>
            </div>
            <div className={`heroItemContainerRight ${loaded ? 'slide-right' : ''}`}>
              <img src={heroImage} className={`heroItemImage ${loaded ? 'slideInTop' : ''}`} alt="Donut" />
            </div>
        </div>
    )
}

export default HeroItem
