import {useState} from 'react';
import "./AddCategory.css";
import FormInput from '../../../../../components/FormInput/FormInput';
import {AiOutlineCheck} from 'react-icons/ai';
import {ImCancelCircle} from 'react-icons/im';
import { toast} from 'react-toastify';
import LoadingComponent from '../../../../../components/LoadingScreen/LoadingScreen';
const categoryService = require ('../../../../../api/categoryService');


function AddCategory() {

    const [categoryName, setCategoryName] = useState('');
    const [categoryImage, setCategoryImage] = useState<File | null>(null);
    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const validateInputNotNull = () => {

        if(!categoryName || !categoryImage){
            return false;
        }

        const hasInvalidFormClass = document.querySelectorAll('.invalidForm').length > 0;
        return !hasInvalidFormClass;
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setSubmitButtonClicked(true);

        if (validateInputNotNull()) {
            setIsLoading(true);

            const formData = new FormData();
            formData.append('categoryName', categoryName);
            formData.append('categoryImage', categoryImage as File);

            try{
                const success = await categoryService.addCategory(formData);

                if (success) {
                    toast.success('Kategorie hinzugefügt', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else {
                    toast.error('Kategorie konnte nicht hinzugefügt werden!', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }catch(error) {
                toast.error('Fehler beim Hinzufügen der Kategorie!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }else{
            toast.error('Stellen Sie sicher, dass Sie alle Kategorieinformationen korrekt ausfüllen!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        setIsLoading(false);
        setTimeout(() => {
            window.location.href = ('/dashboard/categories');
        }, 1500);
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setCategoryImage(event.target.files[0]);
        }
    };

    return (
        <div className='add-category-container'>
            {isLoading && <LoadingComponent/>}
            <div className="add-category-content">
                <h4 className="add-category-header">Fügen Sie eine neue Kategorie hinzu. Füllen Sie unbedingt alle Felder aus</h4>
                <form className="add-category-form">
                    <FormInput submitButtonClicked={submitButtonClicked} inputLabel='Kategoriename' inputType='text' regexType='text' onInputChange={(e) => setCategoryName(e.target.value)} inputValue={categoryName}/>
                    <input id="add-category-file-input" type="file" required accept=".png, .jpg, .jpeg" onChange={handleImageChange}></input>
                    <div className="form-actions">
                        <button className="approve-action-btn" onClick={handleSubmit}><AiOutlineCheck/> Hinzufügen</button>
                        <button className="cancel-action-btn" onClick={() => window.location.href = ('/dashboard/categories')}><ImCancelCircle/> Stornieren</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddCategory
