import React from 'react';
import './AGB.css';
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'

interface Props {}

function AGB(props: Props) {
    const {} = props

    return (
        <>
            <Navbar/>
            <section className="agb-section">
                <h4 className="agb-header">Allgemeine Geschäftsbedingungen Crazy Croffles</h4>
                <hr></hr>
                <div className="agb-content-container">
                    <div className="agb-text-container">
                        <h4 className="agb-header">Allgemeine Informationen</h4>
                        <ul className="agb-content-text">
                            <li>
                                Auf unserer Website finden Sie bestimmte Informationen zu unseren Produkten, teilweise auch kurze Beschreibungen. 
                                Es ist wichtig zu beachten, dass die bereitgestellten 
                                Informationen nur allgemeinen Informationszwecken dienen und nicht als professionelle Beratung oder Empfehlung betrachtet werden sollten.
                            </li>
                        </ul>
                    </div>
                    <div className="agb-text-container">
                        <h4 className="agb-header">Genauigkeit der Informationen</h4>
                        <ul className="agb-content-text">
                            <li>
                                Wir bemühen uns sicherzustellen, dass die auf unserer Website angezeigten Informationen korrekt und aktuell sind. 
                                Wir können jedoch keine Gewähr für die Vollständigkeit, Richtigkeit, Zuverlässigkeit, Eignung oder Verfügbarkeit der 
                                Informationen übernehmen. Jegliches Vertrauen, das Sie auf die Informationen setzen, erfolgt ausschließlich auf Ihr eigenes Risiko.
                            </li>
                        </ul>
                    </div>
                    <div className="agb-text-container">
                        <h4 className="agb-header">Produktverfügbarkeit</h4>
                        <ul className="agb-content-text">
                            <li>
                                Die Verfügbarkeit der auf unserer Website angezeigten 
                                Produkte kann variieren und kann ohne vorherige Ankündigung geändert werden. Wir garantieren nicht, dass ein bestimmtes Produkt jederzeit verfügbar ist.
                            </li>
                        </ul>
                    </div>
                    <div className="agb-text-container">
                        <h4 className="agb-header">Geistiges Eigentum</h4>
                        <ul className="agb-content-text">
                            <li>
                                Alle auf unserer Website verfügbaren Inhalte und Materialien, einschließlich, aber nicht beschränkt auf Texte, Grafiken, Logos, 
                                Bilder und Software, sind Eigentum unseres Unternehmens oder seiner Lizenzgeber und durch geltende Urheberrechte und 
                                Gesetze zum Schutz des geistigen Eigentums geschützt. Die unbefugte Nutzung oder Vervielfältigung jeglicher Inhalte ist strengstens untersagt.
                            </li>
                        </ul>
                    </div>
                    <div className="agb-text-container">
                        <h4 className="agb-header">Links von Drittanbietern</h4>
                        <ul className="agb-content-text">
                            <li>
                                Unsere Website kann zu Ihrer Bequemlichkeit oder als Referenz Links zu Websites Dritter enthalten. 
                                Wir haben jedoch keinen Einfluss auf die Art, den Inhalt und die Verfügbarkeit dieser externen Seiten. 
                                Die Aufnahme von Links stellt keine Empfehlung oder Billigung der darin geäußerten Ansichten dar.
                            </li>
                        </ul>
                    </div>
                    <div className="agb-text-container">
                        <h4 className="agb-header">Haftungsbeschränkung</h4>
                        <ul className="agb-content-text">
                            <li>
                                Wir haften nicht für direkte, indirekte, zufällige, Folge- oder Strafschäden, die sich aus Ihrem Zugriff auf oder 
                                Ihrer Nutzung unserer Website ergeben. Dazu gehören alle Schäden, die durch Viren, 
                                Bugs oder andere technische Probleme entstehen, die Ihre Computerausrüstung oder anderes Eigentum infizieren können.
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default AGB
