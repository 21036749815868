import './Impressum.css';
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'

interface Props {}

function Impressum(props: Props) {
    const {} = props

    return (
        <>
            <Navbar/>
            <section className="impressum-section">
                <h4 className="impressum-header">Impressum</h4>
                <hr></hr>
                <div className="impressum-content-container">
                    <div className="impressum-text-container">
                        <h4 className="impressum-header">Angaben gemäß § 5 TMG:</h4>
                        <ul className="impressum-content-text">
                            <li>Crazy Croffles</li>
                            <li>Rudolf-Breitscheid-Str. 3 <br></br>08280 Aue – Bad Schlema</li>
                            <li>USt-ID Nr : DE357645446 </li>
                            <li>Inh.: Astrit Isufi</li>
                        </ul>
                    </div>
                    <div className="impressum-text-container">
                        <h4 className="impressum-header">Kontakt</h4>
                        <ul className="impressum-content-text">
                            <li>Tel.: +49 15235875146</li>
                            <li>info@crazycroffles.de</li>
                        </ul>
                    </div>
                    <div className="impressum-text-container">
                        <h4 className="impressum-header">Urheberrechte</h4>
                        <ul className="impressum-content-text">
                            <li>
                                Alle Inhalte, einschließlich Texte, Bilder, Grafiken und andere Materialien auf dieser Website, sind durch Urheberrechtsgesetze und internationale Verträge geschützt.
                                Der Inhalt ist Eigentum der Crazy Croffles oder wird von ihr lizenziert. Die unbefugte Nutzung, Vervielfältigung, Verbreitung oder Änderung jeglicher Inhalte dieser Website ist möglich
                                verstoßen gegen Urheberrechtsgesetze und können zu rechtlichen Schritten führen. Es ist Ihnen nicht gestattet, Inhalte dieser Website für kommerzielle Zwecke zu nutzen, zu reproduzieren, zu verbreiten oder zu verändern
                                ohne ausdrückliche schriftliche Zustimmung der Crazy Croffles.
                                Sie können unsere Inhalte jedoch gerne zu nichtkommerziellen und informativen Zwecken teilen und verlinken.
                            </li>
                            <br></br>
                            <li>
                                Für Anfragen zur Nutzung unseres urheberrechtlich geschützten Materials kontaktieren Sie uns bitte unter info@crazycroffles.de © 2023 Crazy Croffles.
                                Alle Rechte vorbehalten.
                            </li>
                        </ul>
                    </div>
                    <div className="impressum-text-container">
                        <h4 className="impressum-header">Haftungsausschluss</h4>
                        <ul className="impressum-content-text">
                            <li>
                                Die auf der Website der Crazy Croffles bereitgestellten Informationen dienen ausschließlich allgemeinen Informationszwecken. 
                                Obwohl wir uns darauf verlassen, dass die Informationen aktuell und korrekt sind, geben wir keinerlei ausdrückliche oder stillschweigende 
                                Zusicherungen oder Gewährleistungen hinsichtlich der Vollständigkeit, Genauigkeit, Zuverlässigkeit, 
                                Eignung oder Verfügbarkeit in Bezug auf die Website oder die darin enthaltenen Informationen Website-Informationen, Informationen, Produkte, 
                                Dienstleistungen oder dekorative Grafiken für jeden Zweck.
                            </li>
                            <br></br>
                            <li>
                                Jegliches Vertrauen, das Sie auf solche Informationen setzen, erfolgt daher ausschließlich auf Ihr eigenes Risiko. In keinem Fall haften wir für Verluste oder Schäden
                                einschließlich, aber nicht beschränkt auf indirekte Verluste oder Folgeschäden oder Verluste oder Schäden jeglicher Art, die sich aus der Nutzung dieser Website ergeben.
                            </li>
                            <br></br>
                            <li>
                                Über diese Website können Sie möglicherweise Links zu anderen Websites herstellen, die nicht unserer Kontrolle unterliegen. Wir haben keine Kontrolle über die Art, den Inhalt und die Verfügbarkeit dieser Websites.
                                Die Aufnahme von Links stellt nicht zwangsläufig eine Empfehlung dar oder befürwortet die darin zum Ausdruck gebrachten Ansichten.
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default Impressum
