import './About.css';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import { BsArrowDownCircleFill } from 'react-icons/bs';
import {Link} from 'react-scroll';

function About() {

    return (
        <>
            <Navbar/>
            <section className="about-hero-section">
                <div className="about-hero-image">  
                    <div className="about-hero-overlay"></div>
                    <div className="about-hero-text">
                        <h4 className="about-hero-text-header">Unsere Croffle-Reise</h4>
                        <Link
                            to="contentSection"
                            smooth={true}
                            duration={500}
                            className="scroll-down-icon">
                            <BsArrowDownCircleFill />
                        </Link>
                    </div>
                </div>
            </section>
            <img src="/uploads/franchise/HeroWave.png" className='waveImage'></img>
            <section id="contentSection" className="about-content-section">
                <div className="about-content-text">
                    <h4 className="about-content-header">Unsere Croffle-Reise</h4>
                    <p>
                        Unsere Croffle-Reise begann vor 5 Jahren, als wir auf unserer Food-Tour durch Südkorea erstmals von einem Croffle gehört haben.
                        Mit dem ersten Biss wurde uns sofort klar: Das muss es auch bei uns in Deutschland geben! Eine Waffel, die wie ein Croissant schmeckt – 
                        ein Croissant, welches einen an der Waffel hat! Was für eine verrückte Idee! Und so nennen wir unsere Croffles – verrückt. 
                        Einfach nur Crazy Croffles!   
                    </p>
                </div>
                <div className="about-content-image">
                    <img src='/uploads/franchise/Franchise3.webp'></img>
                </div>
            </section>
            <section className="about-content-section about-hideMobile-section">
                <div className="about-content-image">
                    <img className="about-hideMobile-image" src="/uploads/franchise/AboutUsMenu.webp"></img>
                </div>
                <div className="about-content-text">
                    <h4 className="about-content-header"><br></br><br></br>Die Mission etwas Einzigartiges zu schaffen</h4>
                    <p>
                        Unsere Mission ist es, für Dich tagtäglich die besten Croffles herzustellen, damit auch Du, so wie wir, verrückt nach ihnen wirst! Nach über 250 Croffle-Sorten haben es nur die leckersten auf unserer Speisekarte geschafft! Und jeden Tag kommen neue Sorten dazu!
                    </p>
                    <button className="about-menu-cta" onClick={() => window.location.href = '/menu'}>Entdecken Sie das Menü</button>
                </div>
            </section>
            <section className="about-content-section">
                <div className="about-content-text">
                    <h4 className="about-content-header">Crazy Croffles Standorte</h4>
                    <ul className='about-content-map-list'>
                        <li>Rudolf-Breitscheid-Str. 3 in 08280 Aue – Bad Schlema </li>
                    </ul>
                </div>
                <div className="about-content-image">
                    <img src='/uploads/franchise/Franchise3.webp'></img>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default About
