import { useState, useEffect } from 'react';
import './EditProduct.css';
import FormInput from '../../../../../components/FormInput/FormInput';
import { AiOutlineCheck } from 'react-icons/ai';
import { ImCancelCircle } from 'react-icons/im';
import { toast } from 'react-toastify';
import LoadingComponent from '../../../../../components/LoadingScreen/LoadingScreen';
const categoryService = require('../../../../../api/categoryService');
const productService = require('../../../../../api/productService');

interface CategoryItem{
    categoryID: number,
    categoryName: string
}

interface ProductType {
    productID: number;
    categoryID: number;
    productName: string;
    productImage: string;
    productPrice: number;
    productDescription: string;
    productIngridients: string;
}

function EditProduct(props: ProductType) {

    useEffect(() => {
        
        const getSimpleCategories = async () => {
            const response = await categoryService.getSimpleCategories();

            if(response){
                setCategories(response)
            }
        }
        
        getSimpleCategories();
    }, []);


    const {categoryID, productID, productName, productImage, productPrice, productDescription, productIngridients} = props;

    const [categories, setCategories] = useState<CategoryItem[] | null>();

    const [newProductName, setNewProductName] = useState(productName);
    const [newProductPrice, setNewProductPrice] = useState<number>(productPrice);
    const [newProductDescription, setNewProductDescription] = useState(productDescription);
    const [newProductIngridients, setNewProductIngridients] = useState(productIngridients);
    const [newProductCategory, setNewProductCategory] = useState<number | undefined>(categoryID);
    const [newProductImage, setNewProductImage] = useState<File | null>(null);

    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setNewProductImage(event.target.files[0]);
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        setIsLoading(true);
        setSubmitButtonClicked(true);

        try {
            if(!newProductImage && (productName === newProductName) && (newProductDescription === productDescription) && (newProductIngridients === productIngridients) && (newProductPrice === productPrice) && (newProductCategory === categoryID )){
                toast.success('Es wurden keine Änderungen vorgenommen!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setIsLoading(false);
                setTimeout(() => {
                    window.location.href = ('/dashboard/products');
                }, 1500);
                return;
            }
            // Delete old image from cloud storage and database
            if (newProductImage) {
                await productService.deleteProductImage(productID);
            }

            // Update category name in the database
            const updateProductInformation = await productService.updateProductInformation(productID, newProductName, newProductCategory, newProductDescription, newProductIngridients, newProductPrice);

            if (newProductImage) {

                // Decide whether we are using the same category name or a new one for image upload
                const targetproductName = newProductName !== productName ? newProductName : productName;
        
                await productService.uploadProductImage(targetproductName, newProductImage);
            }

            toast.success('Produkt erfolgreich aktualisiert!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            toast.error('Das Produkt konnte nicht aktualisiert werden!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }finally {
            setIsLoading(false);
            setTimeout(() => {
                window.location.href = ('/dashboard/products');
            }, 1500);
        }
    };

    return (
        <div className='edit-product-container'>
            {isLoading && <LoadingComponent />}
            <div className="edit-product-content">
                <h4 className="edit-product-header">Produkt bearbeiten. Füllen Sie unbedingt alle Felder aus.</h4>
                <form className="edit-product-form">
                    <FormInput submitButtonClicked={submitButtonClicked} inputLabel='Produktname' inputType='text' regexType='text' onInputChange={(e) => setNewProductName(e.target.value)} inputValue={newProductName} />
                    <div className="form-input-container">
                        <label>Produktpreis</label>
                        <input type='number' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewProductPrice(parseFloat(e.target.value) || 0)} value={newProductPrice}/>
                    </div>
                    <div className="form-textarea-container">
                        <label>Beschreibung</label>
                        <textarea onChange={(e) => setNewProductDescription(e.target.value)} value={newProductDescription}></textarea>
                    </div>
                    <div className="form-textarea-container">
                        <label>Zutaten</label>
                        <textarea onChange={(e) => setNewProductIngridients(e.target.value)} value={newProductIngridients}></textarea>
                    </div>
                    <div className='form-dropdown-container'>
                        <label>Kategorie</label>
                        <select className='form-dropdown' onChange={(e) => setNewProductCategory(Number(e.target.value))} value={newProductCategory}>
                            <option value="">Wählen Sie eine Kategorie</option>
                            {categories && categories.map((category) => (
                                <option key={category.categoryID} value={category.categoryID}>{category.categoryName}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="add-product-file-input" className="add-product-file-label">Choose a File</label>
                        <input id="add-product-file-input" type="file" accept=".png, .jpg, .jpeg" onChange={handleImageChange}/>
                    </div>
                    <div className="form-actions">
                        <button type="submit" className="approve-action-btn"  onClick={handleSubmit}><AiOutlineCheck /> Bestätigen</button>
                        <button className="cancel-action-btn" onClick={() => window.location.href = ('/dashboard/products')}><ImCancelCircle /> Stornieren</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EditProduct;
