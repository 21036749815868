import axios from 'axios';

export const sendEmail = async (formData) => {
    try {
        const { name, surname, email, subject, content } = formData;

        if (!name || !surname || !email || !subject || !content) {
            console.log("Form data is incomplete");
            return false;
        }

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/send-email`, {
            name,
            surname,
            email,
            subject,
            content
        });

        return response.data;
    } catch (error) {
        console.error("Error sending email:", error);
        return false;
    }
};

export const login = async (username, password) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/login`,
        {
            username: username,
            password: password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
  
      if (response.status === 200) {
        return true;
      } else {
        if (response.status === 401) {
          console.log(username, password);
          return false;
        }
      }
    } catch (error) {
      console.error(error);
    }
};

export const verifyAuthentication = async () => {
  try{ 
    const tokenCookie = document.cookie
      .split(';')
      .find((cookie) => cookie.trim().startsWith('token='));

    if (!tokenCookie) {
      return false;
    }

    const token = tokenCookie.split('=')[1];

    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/verify`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if(response.status === 200){
      return true;
    }else{
      return false;
    }

  }catch(error){
    console.error(error);
  }
}