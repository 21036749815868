import './EditCategory.css';
import { useState } from 'react';
import FormInput from '../../../../../components/FormInput/FormInput';
import { AiOutlineCheck } from 'react-icons/ai';
import { ImCancelCircle } from 'react-icons/im';
import { toast } from 'react-toastify';
import LoadingComponent from '../../../../../components/LoadingScreen/LoadingScreen';
const categoryService = require('../../../../../api/categoryService');

interface CategoryType {
    categoryID: number;
    categoryName: string;
    categoryImage: string;
}

function EditCategory(props: CategoryType) {

    const { categoryID, categoryName, categoryImage } = props;

    const [newCategoryName, setNewCategoryName] = useState(categoryName);
    const [newCategoryImage, setNewCategoryImage] = useState<File | null>(null);

    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setNewCategoryImage(event.target.files[0]);
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        setIsLoading(true);
        setSubmitButtonClicked(true);

        try {
            if(!newCategoryImage && (categoryName === newCategoryName)){
                toast.success('Es wurden keine Änderungen vorgenommen!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setIsLoading(false);
                setTimeout(() => {
                    window.location.href = ('/dashboard/categories');	
                }, 1500);
                return;
            }
            // Delete old image from file system
            if (newCategoryImage) {
                await categoryService.deleteCategoryImage(categoryID);
            }

            // Update category name in the database
            const updatedCategory = await categoryService.updateCategoryName(categoryID, newCategoryName);

            if (newCategoryImage) {

                // Decide whether we are using the same category name or a new one for image upload
                const targetCategoryName = newCategoryName !== categoryName ? newCategoryName : categoryName;
        
                await categoryService.uploadCategoryImage(targetCategoryName, newCategoryImage);
            }

            toast.success('Kategorie erfolgreich aktualisiert', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            toast.error('Kategorie konnte nicht aktualisiert werden', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }finally {
            setIsLoading(false);
            setTimeout(() => {
                window.location.href = ('/dashboard/categories');
            }, 1500);
        }
    };

    return (
        <div className='edit-category-container'>
            {isLoading && <LoadingComponent />}
            <div className="edit-category-content">
                <h4 className="edit-category-header">Kategorie bearbeiten. Füllen Sie unbedingt alle Felder aus</h4>
                <form className="edit-category-form">
                    <FormInput submitButtonClicked={submitButtonClicked} inputLabel='Kategoriename' inputType='text' regexType='text' onInputChange={(e) => setNewCategoryName(e.target.value)} inputValue={newCategoryName} />
                    <input id="edit-category-file-input" type="file" accept=".png, .jpg, .jpeg" onChange={handleImageChange}></input>
                    <div className="form-actions">
                        <button type="submit" className="approve-action-btn" onClick={handleSubmit}><AiOutlineCheck /> Bestätigen</button>
                        <button className="cancel-action-btn" onClick={() => window.location.href = ('/dashboard/categories')}><ImCancelCircle /> Stornieren</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EditCategory;
