import {useState, useEffect} from 'react'
import './ProductSlider.css';
import {AiFillLeftCircle, AiFillRightCircle, AiOutlineArrowRight} from 'react-icons/ai'
import {RxDividerVertical} from 'react-icons/rx';
const productService = require('../../api/productService');

interface SliderProduct {
  productID: number,
  productName: string,
  productImage: string,
  productDescription: string
}


function ProductSlider() {

  useEffect(() => {

    const getFeaturedProducts = async () => {
      const response = await productService.getFeaturedProducts();

      if(response){
        setProductSliderItems(response);
      }
    }

    getFeaturedProducts();
  },[]);

  const [productSliderItems, setProductSliderItems] = useState<SliderProduct[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleLeftArrowClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + productSliderItems.length) % productSliderItems.length);
  };
  
  const handleRightArrowClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % productSliderItems.length);
  };

  const currentItem = productSliderItems[currentIndex];
  const leftItemIndex = (currentIndex - 1 + productSliderItems.length) % productSliderItems.length;
  const leftItem = productSliderItems[leftItemIndex];
  const rightItemIndex = (currentIndex + 1) % productSliderItems.length;
  const rightItem = productSliderItems[rightItemIndex];

  return (
    <section id='productSliderContainer'>
      <div className="productSliderHeader">
        <h3>Unsere Leckereien:</h3>
        <svg className="sliderArrowSVG" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 800 800"><g strokeWidth="10" stroke="#542412" fill="none" strokeLinecap="round" strokeLinejoin="round" transform="matrix(1,0,0,1,2,-223)"><path d="M246.08262634277344 250.29412215948105Q535.0826263427734 240.29412215948105 396.08262634277344 400.29412215948105Q589.0826263427734 362.29412215948105 546.0826263427734 550.294122159481 " markerEnd="url(#SvgjsMarker2402)"></path></g><defs><marker markerWidth="10" markerHeight="10" refX="5" refY="5" viewBox="0 0 10 10" orient="auto" id="SvgjsMarker2402"><polyline points="0,5 5,2.5 0,0" fill="none" strokeWidth="1.6666666666666667" stroke="#542412" strokeLinecap="round" transform="matrix(1,0,0,1,1.6666666666666667,2.5)" strokeLinejoin="round"></polyline></marker></defs></svg>
        <a className='goToMenu-cta' href='/menu'>Entdecken Sie das Menü <AiOutlineArrowRight/></a>
      </div>
      <div className="productSliderContent">
      {(productSliderItems && productSliderItems.length > 0) ? (
        <>
          <div className="productSliderChildContainer leftChildContainer">
            <img src={leftItem.productImage} className='productSliderSecondaryImage' alt={leftItem.productName} />
            <div className="productSliderTextContent">
              <h4 className='productSliderTitle productSliderSecondaryTitle'>{leftItem.productName}</h4>
              <p className='productSliderParagraph productSliderSecondaryParagraph'>{leftItem.productDescription}</p>
            </div>
          </div>
          <div className="productSliderChildContainer centerChildContainer">
            <div className='productSliderArrows'>
              <RxDividerVertical />
              <AiFillLeftCircle className='productSliderArrow' onClick={handleLeftArrowClick} />
              <RxDividerVertical />
            </div>
            <div className="centerChildContent">
              <img src={currentItem.productImage} className='productSliderPrimaryImage' alt={currentItem.productName} loading="lazy"/>
              <div className="productSliderTextContent">
                <h4 className='productSliderTitle productSliderPrimaryTitle'>{currentItem.productName}</h4>
                <p className='productSliderParagraph productSliderPrimaryParagraph'>{currentItem.productDescription}</p>
              </div>
            </div>
            <div className='productSliderArrows'>
              <RxDividerVertical />
              <AiFillRightCircle className='productSliderArrow' onClick={handleRightArrowClick} />
              <RxDividerVertical />
            </div>
          </div>
          <div className="productSliderChildContainer rightChildContainer">
            <img src={rightItem.productImage} className='productSliderSecondaryImage' alt={rightItem.productName} loading="lazy"/>
            <div className="productSliderTextContent">
              <h4 className='productSliderTitle productSliderSecondaryTitle'>{rightItem.productName}</h4>
              <p className='productSliderParagraph productSliderSecondaryParagraph'>{rightItem.productDescription}</p>
            </div>
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
    </section>
  );
}

export default ProductSlider
