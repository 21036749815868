import './ProductInformation.css';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
const productService = require('../../api/productService');

interface ProductInformation {
  productImage: string;
  productIngridients: string;
  productPrice: number;
}

function ProductInformation() {
  const { productName } = useParams();
  const [productInfo, setProductInfo] = useState<ProductInformation>({
    productImage: '',
    productIngridients: '',
    productPrice: 0,
  });

  useEffect(() => {
    const getInfo = async () => {
      try {
        const response = await productService.getProductInfo(productName);
        if (response) {
          setProductInfo(response[0]);
        }
      } catch (error) {
        console.error('Error fetching product information:', error);
      }
    };
  
    getInfo();
  }, [productName]);

  useEffect(() => {
    console.log(productInfo.productImage);
    console.log(productInfo.productIngridients);
    console.log(productInfo.productPrice);
  }, [productInfo])

  return (
    <>
      <Navbar />
      <section className='product-information-section'>
        <h4 className='product-information-header'>{productName}</h4>
        <hr></hr>
        <div className="product-information">
          <img src={productInfo.productImage} alt='Product' />
          <div className='product-information-content'>
            <h4 className='product-information-header'>Produktinformation</h4>
            <div className='product-content'>
              {productInfo.productIngridients.trim() != "" ? (
                <p>{productInfo.productIngridients}</p>
              ) : (
                <p>Hoppla! Es scheint, als ob die Zutaten für dieses Produkt noch nicht hinzugefügt wurden.</p>
              )}
              <div>
                <h4 className='product-information-price'>{productInfo.productPrice}€</h4>
                <button className='product-information-cta-btn' onClick={() => window.location.href = ('/menu')}>Zurück zum Menü</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ProductInformation;
