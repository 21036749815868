import "./Categories.css";
import { useState, useEffect } from 'react';
import { toast} from 'react-toastify';
import LoadingComponent from '../../../components/LoadingScreen/LoadingScreen';
import {MdOutlineAdd} from 'react-icons/md'
import AddCategory from './Actions/Add/AddCategory';
import EditCategory from './Actions/Edit/EditCategory';
const categoryService = require('../../../api/categoryService');

interface Props { }
interface CategoryItem {
  categoryID: number;
  categoryName: string;
  categoryImage: string;
}

function Categories(props: Props) {
  const [categories, setCategories] = useState<CategoryItem[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [addCategoryStatus, setAddCategoryStatus] = useState(false);

  const [editCategory, setEditCategory] = useState<CategoryItem | null>(null);
  const [editCategoryStatus, setEditCategoryStatus] = useState(false);


  useEffect(() => {
    const getCategories = async () => {
      const response = await categoryService.getCategories();

      if (response) {
        setCategories(response);
      }
    };

    getCategories();
  }, []);

  const handleAddCategory = async () => {
    setAddCategoryStatus(!addCategoryStatus);
  }

  const handleEditCategory = (category: CategoryItem) => {
    setEditCategory(category);
    setEditCategoryStatus(true);
  }

  const handleDelete = async (categoryID: number) => {

    setIsLoading(true);
    const response = await categoryService.deleteCategory(categoryID);

    if(response){
        toast.success('Kategorie gelöscht!', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        setTimeout(() => {
          window.location.href = ('/dashboard/categories');
        }, 3000);
    }else{
        toast.error('Kategorie konnte nicht gelöscht werden!', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        setTimeout(() => {
          window.location.href = ('/dashboard/categories');
        }, 3000);
    }
    setIsLoading(false);
  }

  return (
    <div className="dashboard-table-container">
        <button className='add-category-btn' onClick={() => handleAddCategory()}><MdOutlineAdd/> Kategorie hinzufügen</button>
        {isLoading && <LoadingComponent/>}
        {addCategoryStatus && <AddCategory/>}
        {editCategoryStatus && editCategory && (
          <EditCategory categoryID={editCategory.categoryID} categoryName={editCategory.categoryName} categoryImage={editCategory.categoryImage} />
        )}
        {categories ? (
        <table className='dashboard-table'>
            <thead>
            <tr className='table-header-row'>
                <th>Kategorie ID</th>
                <th>Kategoriename</th>
                <th>Kategoriebild</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {categories.map((category) => (
                <tr key={category.categoryID} className='table-row'>
                <td className='category-id'>#{category.categoryID}</td>
                <td>{category.categoryName}</td>
                <td><img src={category.categoryImage} alt={category.categoryName} className='category-image' /></td>
                <td><button className='edit-category-btn' onClick={() => handleEditCategory(category)}>Bearbeiten</button></td>
                <td><button className='delete-category-btn' onClick={() => handleDelete(category.categoryID)}>Löschen</button></td>
                </tr>
            ))}
            </tbody>
        </table>
        ) : (
        <div className="no-categories-message">
            <p>Keine Kategorien verfügbar.</p>
        </div>
        )}
    </div>
  );
}

export default Categories;
