import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import Hero from '../../components/Hero/Hero'
import ProductSlider from '../../components/ProductSlider/ProductSlider'
import Map from '../../components/Map/Map'
import Footer from '../../components/Footer/Footer'

interface Props {}

function Home(props: Props) {
    const {} = props

    return (
        <>
            <Navbar/>
            <Hero/>
            <ProductSlider/>
            <Map/>
            <Footer/>
        </>
    )
}

export default Home
