import React, { useState, useEffect } from 'react';
import './CookieConsent.css';

function CookieConsent() {
    const [showBanner, setShowBanner] = useState(false);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setShowBanner(false);
        loadGoogleAnalytics(); // Load GA immediately after consent
    };

    useEffect(() => {
        const consentGiven = localStorage.getItem('cookieConsent');
        if (!consentGiven) {
            setShowBanner(true);
        } else {
            loadGoogleAnalytics(); // Load GA if consent is already given
        }
    }, []);

    const loadGoogleAnalytics = () => {
        const script = document.createElement('script');
        script.async = true;
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-DE43JFSCFM";
        document.head.appendChild(script);
      
        script.onload = () => {
          window.dataLayer = window.dataLayer || [];
          window.gtag = function() {
            window.dataLayer.push(arguments);
          }
          window.gtag('js', new Date());
          window.gtag('config', 'G-DE43JFSCFM');
        };
      
        script.onerror = () => {
          console.error('Google Analytics script failed to load');
        };
      };

    if (!showBanner) return null;

    return (
        <div className="cookie-consent-container">
            <p>Diese Website verwendet Cookies, um sicherzustellen, dass Sie das beste Erlebnis auf unserer Website erhalten.</p>
            <button onClick={handleAccept} className="primaryButton">
                Akzeptieren
            </button>
        </div>
    );
}

export default CookieConsent;
