import {useState, useEffect} from 'react';
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import "./Menu.css";
import LoadingComponent from '../../components/LoadingScreen/LoadingScreen';
import {MdErrorOutline} from 'react-icons/md';

const categoryService = require ('../../api/categoryService');
const productService = require ('../../api/productService');

interface SelectorItem {
    categoryID: number,
    categoryName: string,
    categoryImage: string
}

interface MenuItem {
    productID: number
    categoryID: number
    productName: string
    productImage: string
    productPrice: string
}

function Menu() {

    const [categories, setCategories] = useState<[SelectorItem]>()
    const [items, setItems] = useState<[MenuItem]>()
    const [activeCategory, setActiveCategory] = useState<number | null>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        const getCategories = async () => {
            const response = await categoryService.getCategories();

            if(response){
                setCategories(response)
            }

            setTimeout(() => {

            }, 3000);

            const response2 = await categoryService.loadDefaultCategory();

            if(response2){
                handleCategoryClick(response2[0].categoryID)
            }
        }

        getCategories();
    }, [])

    const handleCategoryClick = async (categoryID: number) => {
        try {

            setIsLoading(true);
            const response = await productService.getProductsByCategory(categoryID);

            if (response) {
                setItems(response);
                setActiveCategory(categoryID);
                setIsLoading(false);
            }else{
                setIsLoading(false);
            }

        } catch (error) {
            console.error('Error fetching items for category:', error);
        }
    };

    return (
        <>
            <Navbar/>
            <section className="menu-page-section">
                {isLoading && <LoadingComponent/>}
                <div className="menu-selector">
                    <h4 className="menu-selector-header">Speisekarte</h4>
                    <ul className="selector-list">
                        {categories && categories.map((category) => (
                        <li key={category.categoryID} className={`selector-item ${category.categoryID === activeCategory ? "active" : ""}`} onClick={() => handleCategoryClick(category.categoryID)}>
                            <img className="selector-item-image" src={category.categoryImage} alt={category.categoryName} />
                            <p className="selector-item-name">{category.categoryName}</p>
                        </li>
                        ))}
                    </ul>
                </div>
                <div className='menu-items'>
                    {categories && <h4 className="menu-selector-header">{categories.find(cat => cat.categoryID === activeCategory)?.categoryName}</h4>}
                    {items && items.length > 0 ? (
                        <ul className='menu-items-list'>
                            {items.map((item) => (
                                <li key={item.productID} className="menu-item" onClick={() => {window.location.href =(`/product-information/${item.productName}`)}}>
                                <img className="menu-item-image" src={item.productImage} alt={item.productName} />
                                    <h4 className="menu-item-name">{item.productName}</h4>
                                    <p className="menu-item-price">{item.productPrice}€</p>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="no-products-message">
                            <MdErrorOutline/>
                            <p>Für diese Kategorie sind keine Produkte verfügbar!</p>
                        </div>
                    )}
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default Menu
