import { Outlet, Link } from 'react-router-dom';
import './Dashboard.css';

function Dashboard() {
  return (
    <>
      <section id='dashboard-section'>
        <aside id='dashboard-aside'>
          <div className='dashboard-header'>
            <a href='/'><img src="/uploads/franchise/BrandLogo.png" alt='CrazyCroffle Logo' className='logo'/></a>
            <h2>CrazyCroffles-Dashboard</h2>
          </div>
          <nav className='dashboard-nav'>
            <ul>
              <li><Link to='/dashboard/categories'>Kategorien</Link></li>
              <li><Link to='/dashboard/products'>Produkte</Link></li>
            </ul>
          </nav>
        </aside>
        <Outlet />
      </section>
    </>
  );
}

export default Dashboard;
