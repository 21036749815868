import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import "./Franchise.css";
import {BsShop} from 'react-icons/bs';


function Franchise() {

    return (
        <>
            <Navbar/>
            <section className="franchise-section">
                <h4 className="franchise-header">Franchise</h4>
                <hr></hr>
                <div className="franchise-content-container">
                    <div className="franchise-text-container">
                        <p className="franchise-content-text">
                            Du hast auch deine Leidenschaft für Crazy Croffles entdeckt und möchtest dein eigenes Business starten und deinen eigenen 
                            Store eröffnen? Wir nehmen Dich von Anfang an an der Hand und führen Dich durch alle Vorgänge und Prozesse samt möglichen 
                            Hindernissen zu deinem eigenen Geschäft. Der Weg in die Selbstständigkeit kann unerwartete Kosten und Zeitaufwände mit sich bringen. 
                            Doch da wir den Weg bereits für Dich gegangen sind, kannst Du Dich voll und ganz auf das Wesentliche fokussieren: Crazy Croffles! 
                        </p>
                        <p className="franchise-content-text">
                            Schreib uns gerne eine Mail mit dem Betreff „Franchise“ an info@crazycroffles.de und sei Dir sicher: Wir melden uns bei Dir!
                        </p>
                    </div>
                    <div className="franchise-image-container">
                        <BsShop/>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default Franchise
