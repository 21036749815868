import axios from 'axios';

export const getCategories = async () => {
    try{

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/category/get-categories`, {
            withCredentials: true,
        });

        if(response && response.data){
            return response.data.map((item) => ({
                categoryID: item.categoryID,
                categoryName: item.categoryName,
                categoryImage: item.categoryImage
            }));
        }

    }catch(error){
        console.error(error);
    }
};

export const loadDefaultCategory = async () => {
    try{

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/category/get-default-category`);

        if(response && response.data){
            return response.data;
        }
        
    }catch(error){
        console.error(error);
    }
}

export const deleteCategory = async (categoryID) => {
    try{

        if(!categoryID){
            return false;
        }
        
        const tokenCookie = document.cookie
        .split(';')
        .find((cookie) => cookie.trim().startsWith('token='));

        if(!tokenCookie){
            window.location.href ='/';
            return null;
        }

        const token = tokenCookie.split('=')[1];

        const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/category/delete/${categoryID}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        });

        if(response.status === 200){
            return true;
        }else{
            return false;
        }

    }catch(error){
        if (error.response && error.response.status === 401) {
            window.location.href = ('/');
        }
        console.error(error);
        return false;
    }
}

export const addCategory = async (formData) => {
    try {

        const tokenCookie = document.cookie
        .split(';')
        .find((cookie) => cookie.trim().startsWith('token='));

        if(!tokenCookie){
            window.location.href ='/';
            return null;
        }

        const token = tokenCookie.split('=')[1];

        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/category/add`,
            formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }
        );

        if (response.status === 200) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            window.location.href = ('/');
        }
        console.error(error);
        return false;
    }
};

export const deleteCategoryImage = async (categoryID) => {
    try {
        if (!categoryID) {
            throw new Error('Category name is required');
        }

        const tokenCookie = document.cookie
        .split(';')
        .find((cookie) => cookie.trim().startsWith('token='));

        if(!tokenCookie){
            window.location.href ='/';
            return null;
        }

        const token = tokenCookie.split('=')[1];

        const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/category/delete-image/${categoryID}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        });

        if(response){
            return true;
        }else{
            return false;
        }
        
    } catch (error) {
        if (error.response && error.response.status === 401) {
            window.location.href = ('/');
        }
        console.error('Error deleting category image:');
        throw error;
    }
};


export const updateCategoryName = async (categoryID, categoryName) => {
    try {

        const tokenCookie = document.cookie
        .split(';')
        .find((cookie) => cookie.trim().startsWith('token='));

        if(!tokenCookie){
            window.location.href ='/';
            return null;
        }

        const token = tokenCookie.split('=')[1];

        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/category/update-name/${categoryID}`,
            { categoryID, categoryName },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }
        );

        return response.data;
        
    } catch (error) {
        if (error.response && error.response.status === 401) {
            window.location.href = ('/');
        }
        console.error('Error updating category name:', error);
        throw error;
    }
};

export const uploadCategoryImage = async (categoryName, categoryImage) => {
    try {
        if (!categoryName || !categoryImage) {
            throw new Error('Category name and image are required.');
        }

        const tokenCookie = document.cookie
        .split(';')
        .find((cookie) => cookie.trim().startsWith('token='));

        if(!tokenCookie){
            window.location.href ='/';
            return null;
        }

        const token = tokenCookie.split('=')[1];

        const formData = new FormData();
        formData.append('categoryName', categoryName);
        formData.append('categoryImage', categoryImage);

        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/category/upload-image`,
            formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        );

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Error uploading category image');
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            window.location.href = ('/');
        }
        console.error('Error uploading category image:', error);
        throw error;
    }
};

export const getSimpleCategories = async () => {
    try{

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/category/get-simple-categories`);

        if(response && response.data){
            return response.data.map((item) => ({
                categoryID: item.categoryID,
                categoryName: item.categoryName,
                categoryImage: item.categoryImage
            }));
        }else{
            throw new Error('Error getting category data');
        }

    }catch(error){
        console.error("Error getting category data");
        throw error;
    }
}


