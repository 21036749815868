import './Footer.css';
import {AiFillInstagram} from 'react-icons/ai';
import {FaTiktok} from 'react-icons/fa';


interface Props {}

function Footer(props: Props) {
    const {} = props

    return (
        <>
        <img className='footer-wave' src="/uploads/franchise/FooterWave.png"></img>
        <div className="footer-container">
            <div className="footer-category footer-socials-category">
                <a href='/'><img className='footer-brand-logo' src="/uploads/franchise/BrandLogo.png"></img></a>
                <p className='footerSectionTitle'>Folgen Sie uns auf Social Media</p>
                <div className='footer-content-container footer-socials-container'>           
                    <a href='https://www.instagram.com/crazycroffles/' target="_blank" rel="noopener noreferrer"><AiFillInstagram className='social-svg'/></a>
                    <a href='https://www.tiktok.com/@crazycroffles_' target="_blank" rel="noopener noreferrer"><FaTiktok className='social-svg'/></a>
                </div>
            </div>
            <div className="footer-category">
                <ul className='footer-content-container'>           
                    <li className='footer-content'><a href='/'>Startseite</a></li>
                    <li className='footer-content'><a href='/menu'>Speisekarte</a></li>
                    <li className='footer-content'><a href='/about'>Über uns</a></li>
                    <li className='footer-content'><a href='/franchise'>Franchise</a></li>
                    <li className='footer-content'><a href='/contact'>Kontakt</a></li>
                    <li className='footer-content'><a href='/impressum'>Impressum</a></li>
                    <li className='footer-content'><a href='/agb'>AGB</a></li>
                </ul>
            </div>
            <div className="footer-category">
                <ul className='footer-content-container footer-contact-container'>
                    <li>info@crazycroffles.de</li>
                    <li>+49 15235875146</li>
                    <li>Rudolf-Breitscheid-Str. 3 <br></br>08280 Aue – Bad Schlema </li>
                </ul>
            </div>
            <div className="footer-category">
                <ul className='footer-content-container footer-contact-container'>
                    <li>Öffnungszeiten:</li>
                    <li>Montag - Freitag: 09:00 - 17:00 Uhr</li>
                    <li>Samstag 12:00 - 18:00 Uhr</li>
                </ul>
            </div>
        </div>
        </>
    )
}

export default Footer
