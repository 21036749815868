import "./Map.css";

interface Props {}

function Map(props: Props) {
    const {} = props

    return (
        <div className="mapContainer">
            <div className="mapContainerHeader">
                <h3>Wo die Magie geschieht:</h3>
                <svg className="mapArrowSVG" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 800 800"><g strokeWidth="10" stroke="#542412" fill="none" strokeLinecap="round" strokeLinejoin="round" transform="matrix(1,0,0,1,2,-223)"><path d="M246.08262634277344 250.29412215948105Q535.0826263427734 240.29412215948105 396.08262634277344 400.29412215948105Q589.0826263427734 362.29412215948105 546.0826263427734 550.294122159481 " markerEnd="url(#SvgjsMarker2402)"></path></g><defs><marker markerWidth="10" markerHeight="10" refX="5" refY="5" viewBox="0 0 10 10" orient="auto" id="SvgjsMarker2402"><polyline points="0,5 5,2.5 0,0" fill="none" strokeWidth="1.6666666666666667" stroke="#542412" strokeLinecap="round" transform="matrix(1,0,0,1,1.6666666666666667,2.5)" strokeLinejoin="round"></polyline></marker></defs></svg>
            </div>
            <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3034.511290406771!2d12.700647812613207!3d50.58703047150009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a0cb0097344993%3A0x6749d45399f712c8!2sCrazy%20Croffles!5e1!3m2!1sde!2s!4v1705354386277!5m2!1sde!2s" 
                width="600" 
                height="450">
            </iframe>
        </div>
    )
}

export default Map
