import './InvalidRoute.css';

function InvalidRoute() {


    return (
        <>
            <section className='invalid-route-section'>
                <div className="invalid-route-overlay"></div>
                <div className="invalid-route-content">
                    <h1>404 Fehler „Nicht gefunden“.</h1>
                    <h5>Hoppla! Anscheinend konnten wir die von Ihnen gesuchte Seite nicht finden. Aber wir kennen einen Ort, an dem Sie leckere Croffles finden können!</h5>
                    <button className="back-btn" onClick={() => window.location.href = '/'}>Zurück nach Croffle Land!</button>
                </div>
            </section>
        </>
    )
}

export default InvalidRoute
