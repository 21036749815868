import React, { useEffect, useState } from 'react';
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/FormInput/FormInput";
import Navbar from "../../components/Navbar/Navbar";
import "./Contact.css";
import { toast} from 'react-toastify';
import LoadingComponent from '../../components/LoadingScreen/LoadingScreen';

const userService = require('../../api/userService');

function Contact() {
    const [formFields, setFormFields] = useState({
        name: "",
        surname: "",
        email: "",
        subject: "",
        content: ""
    });

    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const validateInputNotNull = () => {
        const areFieldsValid = Object.values(formFields).every(value => value.trim() !== "");
        const hasInvalidFormClass = document.querySelectorAll('.invalidForm').length > 0;
    
        return areFieldsValid && !hasInvalidFormClass;
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setSubmitButtonClicked(true);

        if (validateInputNotNull()) {
            setIsLoading(true);
            const success = await userService.sendEmail(formFields);

            if (success) {
                toast.success('E-Mail gesendet!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setTimeout(() => {
                    window.location.href = ('/');
                }, 3000);
            } else {
                toast.error('E-Mail konnte nicht gesendet werden!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else {
            toast.error('Stellen Sie sicher, dass alle Felder ausgefüllt sind!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        setIsLoading(false);
    };

    const handleInputChange = (fieldName: string, value: string) => {
        setFormFields(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
    };    

    return (
        <>
            <Navbar/>
            <section className="contact-section">
                {isLoading && <LoadingComponent/>}
                <h4 className="contact-header">Kontakt</h4>
                <hr></hr>
                <form className="contact-form">
                    <div className="form-input-group">
                        <FormInput submitButtonClicked={submitButtonClicked} inputLabel="Vorname" inputType="text" regexType="text" onInputChange={(e) => handleInputChange("name", e.target.value)} inputValue={formFields.name}/>
                        <FormInput submitButtonClicked={submitButtonClicked} inputLabel="Nachname" inputType="text" regexType="text" onInputChange={(e) => handleInputChange("surname", e.target.value)} inputValue={formFields.surname}/>
                    </div>
                    <FormInput submitButtonClicked={submitButtonClicked} inputLabel="E-Mail" inputType="text" regexType="email" onInputChange={(e) => handleInputChange("email", e.target.value)} inputValue={formFields.email}/>
                    <FormInput submitButtonClicked={submitButtonClicked} inputLabel="Betreff" inputType="text" regexType="text" onInputChange={(e) => handleInputChange("subject", e.target.value)} inputValue={formFields.subject}/>
                    <div className="form-textarea-container">
                        <label>Nachricht</label>
                        <textarea onChange={(e) => handleInputChange("content", e.target.value)}></textarea>
                    </div>
                    <button className="contact-send-btn" onClick={handleSubmit}>Senden</button>
                </form>
            </section>
            <Footer/>
        </>
    )
}

export default Contact;
